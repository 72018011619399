// 【auth】用户注册、认证、授权

import { request, noTimeOutReq } from '@/utils/request.js'

// 展示所有厂商-分页
export function companyPageList (pageIndex, pageSize) {
    return request({
        url: `/company/admin/company/page/${pageIndex}/${pageSize}/list`,
        method: 'post',

    })
}
// 获取厂商详情
export function companyGet (companyId) {
    return request({
        url: `/company/admin/company/get/${companyId}`,
        method: 'post'
    })
}
// 新增厂商
export function companyAdd (data) {
    return request({
        url: '/admin/company/add',
        method: 'post',
        data
    })
}
// 批量删除厂商
export function companyDelete (data) {
    return request({
        url: '/admin/company/delete',
        method: 'post',
        data
    })
}