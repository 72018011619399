<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <!-- icon="el-icon-download" -->
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="addCompanyDialog = true"
        >新增厂商</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="danger"
        @click="deleteList"
        icon="el-icon-delete"
        >批量删除</el-button
      >
    </el-row>
    <el-table
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      ref="multipleTable"
      :data="listCompanyData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="code"
        label="厂商编码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="厂商"
      ></el-table-column>
      <!-- <el-table-column align="center" prop="companySchoolCode" label="厂商关联学校"></el-table-column> -->
      <el-table-column
        align="center"
        prop="companyKey"
        label="厂商Key"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="180">
        <template slot-scope="scope">
          <!-- <el-button v-throttle  size="mini" type="primary" @click="editBtn(scope.$index, scope.row)">编辑</el-button> -->
          <el-button
            v-throttle
            size="mini"
            type="danger"
            @click="deleteBtn(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableDataPagination.pageIndex"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="tableDataPagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableDataPagination.total"
    ></el-pagination>
    <!-- 新增厂商弹框 -->
    <el-dialog title="新增厂商" :visible.sync="addCompanyDialog">
      <div>
        <el-form
          ref="addCompanyData"
          :model="addCompanyData"
          label-width="130px"
        >
          <el-form-item label="厂商编码">
            <el-input
              v-model="addCompanyData.code"
              placeholder="厂商编码"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="厂商管理员账号">
            <el-input v-model="addCompanyData.companyAdminAccount" placeholder="厂商管理员账号"></el-input>
          </el-form-item>
          <el-form-item label="厂商管理员user_id">
            <el-input v-model="addCompanyData.companyAdminUserId" placeholder="厂商管理员user_id"></el-input>
          </el-form-item>
          <el-form-item label="主键">
            <el-input v-model="addCompanyData.companyId" placeholder="主键"></el-input>
          </el-form-item>
          <el-form-item label="厂商Key">
            <el-input v-model="addCompanyData.companyKey" placeholder="厂商Key"></el-input>
          </el-form-item>
          <el-form-item label="厂商salt">
            <el-input v-model="addCompanyData.companySalt" placeholder="厂商salt"></el-input>
          </el-form-item>
          <el-form-item label="厂商关联学校编码">
            <el-input v-model="addCompanyData.companySchoolCode" placeholder="厂商关联学校编码"></el-input>
          </el-form-item>
          <el-form-item label="厂商关联学校">
            <el-input v-model="addCompanyData.companySchoolId" placeholder="厂商关联学校"></el-input>
          </el-form-item>
          <el-form-item label="厂商secret">
            <el-input v-model="addCompanyData.companySecret" placeholder="厂商secret"></el-input>
          </el-form-item>
          <el-form-item label="厂商状态">
            <el-input v-model="addCompanyData.companyStatus" placeholder="厂商状态"></el-input>
          </el-form-item>-->
          <el-form-item label="厂商名称">
            <el-input
              v-model="addCompanyData.name"
              placeholder="厂商名称"
              maxlength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-throttle @click="addCompanyDialog = false"
          >取 消</el-button
        >
        <el-button v-throttle type="primary" @click="companyAddFunc"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  companyPageList,
  companyAdd,
  companyDelete,
} from '@/api/manufacturerDocking/manufacturerDocking.js'
export default {
  name: 'manufacturerDocking',
  data() {
    return {
      // 数据
      listCompanyData: [],
      // 分页
      tableDataPagination: {
        pageIndex: 1, // 当前页
        pageSize: 10, // 页大小
        pageTotal: 1, // 页数
        total: 0, // 总数量
      },
      // 新增厂商
      addCompanyData: {
        code: '', // 厂商编码
        // "companyAdminAccount": "",// 厂商管理员账号
        // "companyAdminUserId": "", // 厂商管理员user_id
        // "companyId": "",          // 主键
        // "companyKey": "",         // 厂商Key
        // "companySalt": "",        // 厂商salt
        // "companySchoolCode": "",  // 厂商关联学校编码
        // "companySchoolId": "",    // 厂商关联学校
        // "companySecret": "",      // 厂商secret
        // "companyStatus": "",      // 厂商状态
        name: '', // 厂商名称
      },
      // 新增厂商弹框
      addCompanyDialog: false,
      // 批量选中
      checkedList: [],
    }
  },
  mounted() {
    this.companyPageListFunc()
  },
  methods: {
    // 获取厂商 分页
    companyPageListFunc() {
      this.checkedList = []
      companyPageList(
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize
      ).then((res) => {
        console.log('获取厂商', res)
        if (res.success) {
          this.listCompanyData = res.data
          this.tableDataPagination.total = res.total
          this.tableDataPagination.pageIndex = res.pageIndex
          this.tableDataPagination.pageSize = res.pageSize
          this.tableDataPagination.pageTotal = res.pageTotal
        }
      })
    },
    handleSizeChange(val) {
      this.checkedList = []
      this.tableDataPagination.pageSize = val
      this.tableDataPagination.pageIndex = 1
      this.companyPageListFunc()
    },
    handleCurrentChange(val) {
      this.checkedList = []
      this.tableDataPagination.pageIndex = val
      this.companyPageListFunc()
    },
    // 左侧选中
    handleSelectionChange(val) {
      this.checkedList = []
      val.forEach((item) => {
        this.checkedList.push(item.companyId)
      })
      console.log(this.checkedList)
    },
    // 批量删除
    deleteList() {
      if (!this.checkedList.length)
        return this.$message.info('所选厂商不能为空')
      companyDelete(this.checkedList).then((res) => {
        if (res.success) {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 编辑
    editBtn(i, val) {},
    // 单个删除
    deleteBtn(i, val) {
      this.checkedList = []
      console.log('单个删除', val.companyId)
      companyDelete([val.companyId]).then((res) => {
        if (res.success) {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        } else {
          this.$message.info(res.msg)
        }
      })
    },
    // 新增厂商
    companyAddFunc() {
      companyAdd(this.addCompanyData).then((res) => {
        if (res.success) {
          this.$message.success('新增成功')
          this.handleCurrentChange(1)
          this.addCompanyDialog = false
        } else {
          this.$message.info(res, msg)
        }
      })
    },
  },
  watch: {
    addCompanyDialog(flag) {
      if (!flag) {
        this.addCompanyData = {
          code: '', // 厂商编码
          // "companyAdminAccount": "",// 厂商管理员账号
          // "companyAdminUserId": "", // 厂商管理员user_id
          // "companyId": "",          // 主键
          // "companyKey": "",         // 厂商Key
          // "companySalt": "",        // 厂商salt
          // "companySchoolCode": "",  // 厂商关联学校编码
          // "companySchoolId": "",    // 厂商关联学校
          // "companySecret": "",      // 厂商secret
          // "companyStatus": "",      // 厂商状态
          name: '', // 厂商名称
        }
      }
    },
  },
}
</script>

<style></style>
